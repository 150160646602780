import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button';
import { useForm, FormProvider } from "react-hook-form";
import Alert from '@mui/material/Alert';
import FormControl from '@mui/material/FormControl';
import { Helmet } from "react-helmet-async";
import { Link as RouterLink } from 'react-router-dom';
import {
  ANO_INICIO_PROGRAMA_DE_NOMINACAO,
  MAX_LENGTH_REGISTRO,
  MIN_LENGTH_REGISTRO,
  MAX_LENGTH_NOME_ANIMAL,
  MIN_LENGTH_NOME_ANIMAL,
  DEBUG_FORM_DATA,
} from "../../constants";
import { axiosInstance as axios } from '../../axiosHook/axiosInstance';
import {
  RACAS_DEFAULT_DATA,
  FORMAS_DE_PAGAMENTO_DEFAULT_DATA,
  OPCOES_PAGAMENTO_DEFAULT_DATA,
  SEXOS_ANIMAIS_DEFAULT_DATA,
} from '../../default-data';
import {
  // VALOR_NOMINACAO_TEST_DATA,
} from '../../test-data';
import {
  dateFromString,
  formatMoney,
} from '../../functions';
import {
  FormGroup,
  FormHelperText,
  FormLabel,
  InputAdornment,
  LinearProgress,
  CircularProgress,
} from '@mui/material';
import AlertDialog from '../AlertDialog';
import pagarme from 'pagarme'
import moment from 'moment';
import _ from 'lodash';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import RHookFormAutocompleteWithGet from '../../form/RHookFormAutocompleteWithGet';
import RHookFormTextField from '../../form/RHookFormTextField';
import RHookFormSelect from '../../form/RHookFormSelect';
import RHookFormRadio from '../../form/RHookFormRadio';
import RHookFormCheckbox from '../../form/RHookFormCheckbox';
import RHookFormDateField from '../../form/RHookFormDateField';
import CheckIcon from '@mui/icons-material/Check';
import { RHookFormDataDebugger } from '../../form/RHookFormDataDebugger';
import ScrollToTopOnMount from '../ScrollToTopOnMount';

const formControlStyle = {
  m: 3,
  width: "85%"
};

const formControlSpacing = {
  mt: 1,
};

const isFutureOrBeforeStartOfProgram = (date) => {
  if (!date) {
    return false;
  }
  const dateFromString = new Date(date);
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return dateFromString < today && dateFromString.getFullYear() >= ANO_INICIO_PROGRAMA_DE_NOMINACAO;
};

const idCartaoDeCredito = FORMAS_DE_PAGAMENTO_DEFAULT_DATA["hydra:member"].find(forma => forma.cartaoDeCredito).id;
const idOpcaoPagamento = OPCOES_PAGAMENTO_DEFAULT_DATA["hydra:member"].find(opcao => opcao.numeroParcelas === 1).id;

const defaultValuesByStep = [{
  provisoria: false,
  nominacaoProvisoria: null,
}, {
  idRaca: "",
  registro: "",
  nomeAnimal: "",
  nascimento: null,
  idSexo: "",
  pai: "",
  registroPai: "",
  mae: "",
  registroMae: "",
  nominador: "",
  email: "",
}, {
  idFormaPagamento: idCartaoDeCredito,
  idOpcaoPagamento: idOpcaoPagamento,
  cardNumber: "",
  cardHolderName: "",
  cardExpirationMonth: "",
  cardExpirationYear: "",
  cardCvv: "",
  autorizo: false,
}];

const defaultValues = _.merge({}, defaultValuesByStep[0], defaultValuesByStep[1], defaultValuesByStep[2]);

export default function NominationForm(props) {
  const { helmetContext } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [pagarmeEncryptionKey, setPagarmeEncryptionKey] = useState("");

  const methods = useForm({
    defaultValues,
    mode: "onTouched",
    reValidateMode: "onChange",
  });

  const {
    handleSubmit,
    formState: { errors, isValid, isDirty },
    watch,
    getValues,
    setValue,
    setError,
    trigger,
    control,
  } = methods;

  const onSubmit = data => {
    // console.log(data);
    // alert(JSON.stringify(data));
    setIsSubmitting(true);

    const card = {
      card_number: data.cardNumber,
      card_holder_name: data.cardHolderName,
      card_expiration_date: `${data.cardExpirationMonth}${data.cardExpirationYear}`,
      card_cvv: data.cardCvv,
    };

    // remove sensitive card information from data to be sent to server
    let dataToSend = {};
    Object.keys(data).forEach(key => (key.includes("card") || key.includes("nominador") || key.includes("nominacaoProvisoria")) ? null : dataToSend[key] = data[key]);
    dataToSend.idNominador = data.nominador.id; // send just the id, no need for the full name and info
    dataToSend.idNominacaoProvisoria = data.nominacaoProvisoria?.id; // send just the id, no need for the full info
    dataToSend.nascimento = moment(data.nascimento).format("YYYY-MM-DD"); // send just the date, no need for the full timestamp

    pagarme.client.connect({ encryption_key: pagarmeEncryptionKey })
      .then(client => client.security.encrypt(card))
      .then(card_hash => {
        dataToSend.cardHash = card_hash;
        // alert(JSON.stringify(dataToSend, null, 2));
        axios
          .post(`/efetuarnominacao`, dataToSend)
          .then((response) => {
            const data = response.data;
            // console.log(data);
            setModalTitle(`Nominação ${data.nominacao} efetuada com sucesso`);
            setModalMessage(data.confirmacaoPagamento);
            setModalOpen(true);
            // reset form foal data (but not nominator or payment info, 
            // so subsequent nominations from the same member can be made easily)
            setValue('provisoria', defaultValues.provisoria);
            setValue('nominacaoProvisoria', defaultValues.nominacaoProvisoria);
            setValue('idRaca', defaultValues.idRaca);
            setValue('nomeAnimal', defaultValues.nomeAnimal, { shouldDirty: true });
            setValue('registro', defaultValues.registro);
            setValue('idSexo', defaultValues.idSexo);
            setValue('nascimento', defaultValues.nascimento);
            setValue('pai', defaultValues.pai);
            setValue('registroPai', defaultValues.registroPai);
            setValue('mae', defaultValues.mae);
            setValue('registroMae', defaultValues.registroMae);
            setActiveStep(0); // go back to first form page

            // Note: this is here and not in a finally statement, 
            // as it was firing before axios returned
            // allowing double entries/payments to be made 
            setIsSubmitting(false);
          })
          .catch((error) => {
            // console.log("error", error);
            setModalTitle("Error");
            setModalMessage({
              type: "error",
              message: error.response?.data?.detail ?? error.message,
            });
            setModalOpen(true);
            setIsSubmitting(false);
          });
      })
  };

  const [racas, setRacas] = useState(RACAS_DEFAULT_DATA);
  const [sexosAnimais, setSexosAnimais] = useState(SEXOS_ANIMAIS_DEFAULT_DATA);
  const [formasPagamento, setFormasPagamento] = useState(FORMAS_DE_PAGAMENTO_DEFAULT_DATA);
  const [opcoesPagamento, setOpcoesPagamento] = useState(OPCOES_PAGAMENTO_DEFAULT_DATA);
  // const [valorNominacao, setValorNominacao] = useState(VALOR_NOMINACAO_TEST_DATA);
  const [valorNominacao, setValorNominacao] = useState({});
  const [dadosAnimalFromRegistro, setDadosAnimalFromRegistro] = useState(false);
  const [pesquisandoRegistro, setPesquisandoRegistro] = useState(false);

  const getRacas = () => {
    axios
      .get(`/racas`)
      .then((response) => {
        const data = response.data;
        setRacas(data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  const getSexosAnimais = () => {
    axios
      .get(`/sexos_animais`)
      .then((response) => {
        const data = response.data;
        setSexosAnimais(data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  const getFormasPagamento = () => {
    axios
      .get(`/formas_pagamentos`)
      .then((response) => {
        const data = response.data;
        setFormasPagamento(data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  const getOpcoesPagamento = () => {
    axios
      .get(`/opcoes_pagamentos`)
      .then((response) => {
        const data = response.data;
        setOpcoesPagamento(data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  const getPagarmeEncrytionKey = () => {
    axios
      .get(`/organizador`)
      .then((response) => {
        const data = response.data;
        setPagarmeEncryptionKey(data.chave);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  useEffect(() => {
    getRacas();
    getSexosAnimais();
    getFormasPagamento();
    getOpcoesPagamento();
    getPagarmeEncrytionKey();
  }, []);

  const nascimento = watch("nascimento");

  const getValorNominacao = (nascimento) => {
    if (nascimento === defaultValues.nascimento) {
      // after resetting the form, there shouldn't be any amount shown
      setValorNominacao({});
      return undefined;
    }

    axios
      .get(`/valornominacao?nascimento=${moment(nascimento).format("YYYY-MM-DD")}`)
      .then((response) => {
        const data = response.data;
        setValorNominacao(data);
      })
      .catch((error) => {
        setValorNominacao({
          "error": error,
        });
        console.log("error", error);
      });
  }

  const provisoria = watch("provisoria");
  const nominacaoProvisoria = watch("nominacaoProvisoria");
  const idFormaPagamento = watch("idFormaPagamento");
  // console.log(provisoria, nominacaoProvisoria);
  // default to 1 payment if installment options aren't available
  const maxParcelas = valorNominacao?.opcaoPagamento?.numeroParcelas ?? 1;

  useEffect(() => {
    // check foal date is valid, and after the program began
    if (!nascimento) return undefined;
    if (nominacaoProvisoria?.pago) {
      setValorNominacao({});
      return undefined;
    }

    let dataNascimento;
    let nascimentoFormatado;
    if (nascimento._isAMomentObject) {
      dataNascimento = nascimento.toDate();
      nascimentoFormatado = nascimento.format("YYYY-MM-DD")
    } else {
      dataNascimento = dateFromString(nascimento);
      nascimentoFormatado = nascimento;
    }

    if (dataNascimento && dataNascimento.getFullYear() >= ANO_INICIO_PROGRAMA_DE_NOMINACAO) {
      getValorNominacao(nascimentoFormatado);
    }
  }, [nascimento, nominacaoProvisoria]);

  const idRaca = watch("idRaca");
  const registro = watch("registro");

  useEffect(() => {
    // check associations for valid registration
    if (!registro || !idRaca || registro.length < MIN_LENGTH_REGISTRO) {
      setDadosAnimalFromRegistro(false);
      setPesquisandoRegistro(false);
      return undefined;
    }

    setPesquisandoRegistro(true);

    axios
      .get(`/pesquisarregistro?registro=${registro}&idRaca=${idRaca}`, {
        // validateStatus: function (status) {
        // console.log(status);
        // return status < 500; // Resolve only if the status code is less than 500 (404 should be common)
        // }
      })
      .then((response) => {
        const data = response.data;
        if (data.nome) {
          if (nominacaoProvisoria && !moment(data.nascimento, "DD/MM/YYYY").isSame(moment(nominacaoProvisoria.nascimento, 'YYYYMMDD'), 'day')) {
            // console.log(`Error: o nacimento no registro (${data.nascimento}) é diferente da nominação provisória (${nominacaoProvisoria.nascimento})`);
            setError("registro", {
              type: "nascimento",
            });
            setValue('nomeAnimal', defaultValues.nomeAnimal, { shouldDirty: true });
            return undefined;
          }
          // Note: We reset the registration to the returned value, as the ABQM search will truncate the string sent
          setValue('registro', data.registro, { shouldDirty: true });
          setValue('nomeAnimal', data.nome, { shouldDirty: true });
          if (!nominacaoProvisoria) {
            setValue('idSexo', `${data.idSexo}`, { shouldDirty: true });
            setValue('nascimento', moment(data.nascimento, "DD/MM/YYYY").format("YYYY-MM-DD"), { shouldDirty: true });
            setValue('pai', data.pai, { shouldDirty: true });
            setValue('registroPai', data.registroPai, { shouldDirty: true });
            setValue('mae', data.mae, { shouldDirty: true });
            setValue('registroMae', data.registroMae, { shouldDirty: true });
          }
          setDadosAnimalFromRegistro(true);
          trigger('registro', 'nomeAnimal');
        }
      })
      .catch((error) => {
        setDadosAnimalFromRegistro(false);
        if (!error.message.includes("404")) {
          console.log(JSON.stringify(error));
        }
      })
      .finally(() => setPesquisandoRegistro(false));
  }, [idRaca, registro, nominacaoProvisoria, setValue, trigger, setError]);

  // pega os erros de validação nos campos do form e a bandeira do cartão  
  const validarCartaoPeloPagarme = campo => {
    if (idFormaPagamento !== idCartaoDeCredito) return true;
    // if this is a provisory nomination, and it has been paid, then we don't need to validate card details
    if (nominacaoProvisoria?.pago) return true;

    const card = {
      card_number: getValues('cardNumber'),
      card_holder_name: getValues('cardHolderName'),
      card_expiration_date: `${getValues('cardExpirationMonth')}/${getValues('cardExpirationYear')}`,
      card_cvv: getValues('cardCvv'),
    };
    return pagarme.validate({ card: card }).card[campo];
  }
  const validarNumeroDoCartaoPeloPagarme = () => validarCartaoPeloPagarme('card_number');
  const validarNomeNoCartaoPeloPagarme = () => validarCartaoPeloPagarme('card_holder_name');
  const validarVencimentoDoCartaoPeloPagarme = () => validarCartaoPeloPagarme('card_expiration_date');
  const validarCodigoVerificadorDoCartaoPeloPagarme = () => validarCartaoPeloPagarme('card_cvv');

  const checkIfNominatorHasPaidMembershipFee = async (nominador) => {
    const url = `/verificarnominador?idNominador=${nominador.id}`;
    try {
      const response = await axios.get(url);
      const data = response.data;
      return 1 === data || `O nominador precisa estar em dia com a anuidade para efetuar uma nominação.`;
    } catch (error) {
      if (process.env.NODE_ENV !== 'production') {
        console.error(error);
      }
      if (error?.response?.status === 404) {
        return error.response?.data?.detail ?? error.message;
      }

      return `Erro conectando ao servidor! ${error}`;
    }
  };

  const handleChangeProvisoria = () => {
    setValue("provisoria", !getValues("provisoria"));
    setValue('registro', defaultValues.registro, { shouldValidate: true });
    setValue('nomeAnimal', defaultValues.nomeAnimal, { shouldValidate: true });
  }

  useEffect(() => {
    setDadosAnimalFromRegistro(false);
    if (!nominacaoProvisoria) {
      return undefined;
    }

    // if we are completing a provisory nomination from before, then this option should be false.
    setValue('provisoria', defaultValues.provisoria, { shouldDirty: true });

    // clear any entered name and registration data
    setValue('registro', defaultValues.registro, { shouldDirty: true });
    setValue('nomeAnimal', defaultValues.nomeAnimal, { shouldDirty: true });

    // get foal date and parentage from provisory nomination data
    setValue('idRaca', nominacaoProvisoria.raca.id, { shouldDirty: true });
    setValue('idSexo', `${nominacaoProvisoria.sexo.id}`, { shouldDirty: true });
    setValue('nascimento', moment(nominacaoProvisoria.nascimento, "YYYYMMDD").format("YYYY-MM-DD"), { shouldDirty: true });
    setValue('pai', nominacaoProvisoria.pai, { shouldDirty: true });
    setValue('registroPai', nominacaoProvisoria.registroPai, { shouldDirty: true });
    setValue('mae', nominacaoProvisoria.mae, { shouldDirty: true });
    setValue('registroMae', nominacaoProvisoria.registroMae, { shouldDirty: true });
    setValue('nominador', nominacaoProvisoria.nominador, { shouldDirty: true });

    // if paid then clear card details
    if (nominacaoProvisoria.pago) {
      setValue('cardNumber', defaultValues.cardNumber, { shouldDirty: true });
      setValue('cardHolderName', defaultValues.cardHolderName, { shouldDirty: true });
      setValue('cardExpirationMonth', defaultValues.cardExpirationMonth, { shouldDirty: true });
      setValue('cardExpirationYear', defaultValues.cardExpirationYear, { shouldDirty: true });
      setValue('cardCvv', defaultValues.cardCvv, { shouldDirty: true });
      setValorNominacao({});
    }

    trigger(); // check form
  }, [nominacaoProvisoria, setValue, trigger]);


  const validarRegistroPotro = () => provisoria || (getValues("registro")?.length >= MIN_LENGTH_REGISTRO && getValues("registro")?.length < MAX_LENGTH_REGISTRO && dadosAnimalFromRegistro && (idRaca === 1 || idRaca === 2)) || idRaca > 2;
  const validarNomePotro = () => provisoria || (getValues("nomeAnimal")?.length >= MIN_LENGTH_NOME_ANIMAL && getValues("nomeAnimal")?.length <= MAX_LENGTH_NOME_ANIMAL);


  const [activeStep, setActiveStep] = useState(0);

  const handleChangeAutorizo = () => {
    setValue("autorizo", !getValues("autorizo"));
    trigger(Object.keys(defaultValuesByStep[activeStep]));
  };

  function getStepContent(stepIndex) {
    // console.log(errors);
    // console.log({ isValid });
    // console.log(Object.keys(dirtyFields));

    switch (stepIndex) {

      case 0:
        return (
          <FormControl component="fieldset" variant="standard" sx={formControlStyle}>
            <FormLabel component="legend">Nominação Provisória</FormLabel>
            <FormGroup>


              <RHookFormCheckbox
                name="provisoria"
                label="Nominação Provisória"
                formHelperText="Se ainda estiver esperando para receber o registro do potro."
                disabled={!!nominacaoProvisoria}
                onChange={handleChangeProvisoria}
                helperText="Se o potro não tiver registro ainda, selecione essa opção."
              />


              <RHookFormAutocompleteWithGet
                label="Número"
                name="nominacaoProvisoria"
                optionLabel="id"
                apiSearchUrl="/nominacao_potros?page=1&exists[animal]=false&search="
              />
              <FormHelperText>Se quiser completar uma nominação provisória feita anteriormente, digite o número.</FormHelperText>

            </FormGroup>
          </FormControl>
        );


      case 1:
        return <>
          <FormControl component="fieldset" variant="standard" sx={formControlStyle}>
            <FormLabel component="legend">Dados do Animal</FormLabel>
            <FormGroup>


              <RHookFormSelect
                sx={{ mt: 1 }}
                fullWidth
                formHelperTextErrorClassName="formSelectErrorMessage"
                name="idRaca"
                label="Raça"
                id="idRaca"
                rules={{ required: true }}
                options={racas["hydra:member"]?.map(raca => {
                  return {
                    value: raca.id,
                    label: raca.descricao,
                  };
                })}
                helperText=" "
                disabled={!!nominacaoProvisoria}
                errorMessages={
                  [{
                    type: "required",
                    message: "É necessário selecionar uma raça!",
                  }]}
              />

              <RHookFormTextField
                name="registro"
                label="Registro"
                id="registro"
                key="registro"
                sx={{ mt: 1 }}
                fullWidth
                inputProps={{ maxLength: MAX_LENGTH_REGISTRO, }}
                InputProps={{
                  endAdornment:
                    <InputAdornment position="end">
                      {pesquisandoRegistro
                        ? <CircularProgress size={20} />
                        : dadosAnimalFromRegistro
                          ? <CheckIcon />
                          : ''}
                    </InputAdornment>,
                }}
                rules={{ validate: validarRegistroPotro }}
                disabled={!!provisoria}
                errorMessages={
                  [{
                    type: "validate",
                    message: "É necessário informar o número de registro do potro! Se o animal ainda não tiver registro selecione a opção de nominação provisória na etapa anterior.",
                  }, {
                    type: "nascimento",
                    message: "O nacimento no registro precisa ser igual da nominação provisória!",
                  }]}
                helperText=" "
              />


              <RHookFormTextField
                name="nomeAnimal"
                label="Nome"
                id="nomeAnimal"
                sx={{ mt: 1 }}
                fullWidth
                inputProps={{ maxLength: MAX_LENGTH_NOME_ANIMAL, readOnly: dadosAnimalFromRegistro }}
                rules={{ validate: validarNomePotro }}
                disabled={!!provisoria}
                errorMessages={
                  [{
                    type: "validate",
                    message: "É necessário informar o nome do potro!",
                  }]}
                helperText=" "
              />


              <RHookFormDateField
                name="nascimento"
                sx={{ mt: 1 }}
                fullWidth
                label="Nascimento"
                rules={{ required: true, validate: isFutureOrBeforeStartOfProgram }}
                error={!!errors?.nascimento}
                readOnly={dadosAnimalFromRegistro || !!nominacaoProvisoria}
                formHelperTextErrorClassName="formError"
                formHelperText=" "
                errorMessages={
                  [{
                    type: "required",
                    message: "É necessário informar um nascimento válido!",
                  }, {
                    type: "validate",
                    message: `O nascimento não pode ser no futuro, nem antes do início do programa em ${ANO_INICIO_PROGRAMA_DE_NOMINACAO}!`,
                  }]}
              />


              <RHookFormRadio
                sx={{ mt: 1 }}
                fullWidth
                formHelperTextErrorClassName="formSelectErrorMessage"
                id="idSexo"
                name="idSexo"
                label="Sexo"
                rules={{ required: true }}
                disabled={dadosAnimalFromRegistro || !!nominacaoProvisoria}
                options={sexosAnimais["hydra:member"].map(sexo => {
                  return {
                    value: sexo.id.toString(),
                    label: sexo.descricao,
                  };
                })}
                helperText=" "
                errorMessages={
                  [{
                    type: "required",
                    message: "É necessário informar o sexo!",
                  }]}
              />

            </FormGroup>
          </FormControl>

          <FormControl component="fieldset" variant="standard" sx={formControlStyle}>
            <FormLabel component="legend">Filiação</FormLabel>
            <FormGroup>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={8}>

                  <RHookFormTextField
                    name="pai"
                    label="Pai"
                    id="pai"
                    sx={formControlSpacing}
                    fullWidth
                    // eslint-disable-next-line
                    rules={{ required: true, minLength: MIN_LENGTH_NOME_ANIMAL, pattern: /^[\s0-9A-Za-z\u00C0-\u017F\.\'()-]+$/i }}
                    inputProps={{ maxLength: MAX_LENGTH_NOME_ANIMAL, readOnly: dadosAnimalFromRegistro || !!nominacaoProvisoria }}
                  />

                </Grid>
                <Grid item xs={4}>

                  <RHookFormTextField
                    name="registroPai"
                    label="Registro"
                    id="registroPai"
                    sx={formControlSpacing}
                    fullWidth
                    rules={{ required: true, minLength: MIN_LENGTH_REGISTRO, maxLength: MAX_LENGTH_REGISTRO, pattern: /^[\sA-Za-z0-9.-]+$/i }}
                    inputProps={{ maxLength: MAX_LENGTH_REGISTRO, readOnly: /* dadosAnimalFromRegistro  || */ !!nominacaoProvisoria }}
                  />

                </Grid>
              </Grid>
              <FormHelperText className={(errors?.pai || errors?.registroPai) ? "formError" : null}>
                {(errors?.pai?.type === "required" || errors?.registroPai?.type === "required")
                  ? "É necessário informar o nome é o registro do pai!"
                  : errors?.pai?.type === "pattern"
                    ? "O nome não pode incluir números ou caracteres especiais!"
                    : errors?.registroPai?.type === "pattern"
                      ? "O registro não pode incluir caracteres especiais!"
                      : (errors?.registroPai?.type === "minLength" || errors?.registroPai?.type === "maxLength")
                        ? `O número de registro deve ser entre ${MIN_LENGTH_REGISTRO} e ${MAX_LENGTH_REGISTRO} caracteres!`
                        : "Informar o nome e registro do pai"}
              </FormHelperText>

              <Grid container direction="row" spacing={2}>
                <Grid item xs={8}>

                  <RHookFormTextField
                    name="mae"
                    label="Mãe"
                    id="mae"
                    sx={formControlSpacing}
                    fullWidth
                    // eslint-disable-next-line
                    rules={{ required: true, minLength: MIN_LENGTH_NOME_ANIMAL, pattern: /^[\s0-9A-Za-z\u00C0-\u017F\.\'()-]+$/i }}
                    inputProps={{ maxLength: MAX_LENGTH_NOME_ANIMAL, readOnly: dadosAnimalFromRegistro || !!nominacaoProvisoria }}
                  />

                </Grid>
                <Grid item xs={4}>

                  <RHookFormTextField
                    name="registroMae"
                    label="Registro"
                    id="registroMae"
                    sx={formControlSpacing}
                    fullWidth
                    rules={{ required: true, minLength: MIN_LENGTH_REGISTRO, maxLength: MAX_LENGTH_REGISTRO, pattern: /^[\sA-Za-z0-9.()-]+$/i }}
                    inputProps={{ maxLength: MAX_LENGTH_REGISTRO, readOnly: /* dadosAnimalFromRegistro || */ !!nominacaoProvisoria }}
                  />

                </Grid>
              </Grid>
              <FormHelperText className={(errors?.mae || errors?.registroMae) ? "formError" : null}>
                {(errors?.mae?.type === "required" || errors?.registroMae?.type === "required")
                  ? "É necessário informar o nome é o registro da mãe!"
                  : errors?.mae?.type === "pattern"
                    ? "O nome não pode incluir números ou caracteres especiais!"
                    : errors?.registroMae?.type === "pattern"
                      ? "O registro não pode incluir caracteres especiais!"
                      : (errors?.registroMae?.type === "minLength" || errors?.registroMae?.type === "maxLength")
                        ? `O número de registro deve ser entre ${MIN_LENGTH_REGISTRO} e ${MAX_LENGTH_REGISTRO} caracteres!`
                        : "Informar o nome e registro da mãe"}
              </FormHelperText>


            </FormGroup>
          </FormControl>
          <FormControl component="fieldset" variant="standard" sx={formControlStyle}>
            <FormLabel component="legend">Nominador</FormLabel>
            <FormGroup>

              <RHookFormAutocompleteWithGet
                label="Nome"
                name="nominador"
                optionLabel="nome"
                apiSearchUrl="/afiliados?search="
                rules={{
                  required: "É necessário selecionar o nome!",
                  validate: {
                    nominatorHasPaidMembershipFee: async (value) => await checkIfNominatorHasPaidMembershipFee(value),
                  }
                }}
                error={!!errors?.nominador}
                disabled={!!nominacaoProvisoria}
              />
              <FormHelperText className={errors?.nominador ? "formError" : null}>
                {errors?.nominador
                  ? errors.nominador.message
                  : "Se o nome não aparecer na lista, favor, cadastre o nominador."}
              </FormHelperText>


              <RHookFormTextField
                name="email"
                label="Email"
                id="email"
                sx={formControlSpacing}
                fullWidth
                inputProps={{ maxLength: 100 }}
                rules={{ required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i }}
                errorMessages={
                  [{
                    type: "required",
                    message: "É necessário informar um email válido!",
                  }, {
                    type: "pattern",
                    message: "É necessário informar um email válido!",
                  }]}
                helperText="Uma mensagem de confirmação será enviada neste endereço."
              />


            </FormGroup>
          </FormControl>

        </>;


      case 2:
        return (
          <>
            <FormControl component="fieldset" variant="standard" sx={formControlStyle}>
              <FormLabel component="legend">Pagamento</FormLabel>
              {nominacaoProvisoria?.pago
                ? 'Essa nominação já está quitada'
                :
                <FormGroup>
                  {valorNominacao.valorCheio
                    ? <Typography>Valor {formatMoney(valorNominacao.valorCheio)}<br />{valorNominacao.descricao}</Typography>
                    : valorNominacao.error
                      ? <Alert severity="error">{`${valorNominacao.error}`}</Alert>
                      : null}


                  <RHookFormSelect
                    sx={formControlSpacing}
                    fullWidth
                    formHelperTextErrorClassName="formSelectErrorMessage"
                    name="idFormaPagamento"
                    label="Forma de Pagamento"
                    id="idFormaPagamento"
                    rules={{ required: true }}
                    options={formasPagamento["hydra:member"]?.filter(forma => forma.ativo).map(forma => {
                      return {
                        value: forma.id,
                        label: forma.descricao,
                      };
                    })}
                    helperText=" "
                    disabled={!!nominacaoProvisoria}
                    errorMessages={
                      [{
                        type: "required",
                        message: "É necessário selecionar uma forma de pagamento!",
                      }]}
                    onChange={(e) => {
                      setValue('idFormaPagamento', e.target.value);
                      trigger(Object.keys(defaultValuesByStep[activeStep]))
                    }}
                  />

                  {idFormaPagamento === idCartaoDeCredito &&
                    <React.Fragment>

                      {maxParcelas > 1
                        ? <RHookFormSelect
                          sx={formControlSpacing}
                          fullWidth
                          formHelperTextErrorClassName="formSelectErrorMessage"
                          name="idOpcaoPagamento"
                          label="Parcelar"
                          rules={{ required: true }}
                          options={opcoesPagamento["hydra:member"]?.filter(opcao => opcao.numeroParcelas <= maxParcelas)?.map(opcao => {
                            return {
                              value: opcao.id,
                              label: opcao.descricao,
                            };
                          })}
                          helperText=" "
                          errorMessages={
                            [{
                              type: "required",
                              message: "É necessário selecionar uma forma de pagamento!",
                            }]}
                        />
                        : null
                      }

                      <RHookFormTextField
                        name="cardNumber"
                        key="cardNumber"
                        label="Número do cartão"
                        sx={formControlSpacing}
                        fullWidth
                        disabled={!!nominacaoProvisoria || idFormaPagamento !== idCartaoDeCredito}
                        inputProps={{ maxLength: 19 }}
                        rules={{ required: !nominacaoProvisoria?.pago, validate: validarNumeroDoCartaoPeloPagarme }}
                        errorMessages={
                          [{
                            type: "required",
                            message: "É necessário informar o número do cartão!",
                          }, {
                            type: "validate",
                            message: "Verifique o número do cartão!",
                          }]}
                        helperText=" "
                      />

                      <RHookFormTextField
                        name="cardHolderName"
                        label="Nome no cartão"
                        key="cardHolderName"
                        id="cardHolderName"
                        sx={formControlSpacing}
                        fullWidth
                        disabled={!!nominacaoProvisoria || idFormaPagamento !== idCartaoDeCredito}
                        inputProps={{ maxLength: 25 }}
                        rules={{ required: !nominacaoProvisoria?.pago, validate: validarNomeNoCartaoPeloPagarme }}
                        errorMessages={
                          [{
                            type: "required",
                            message: "É necessário informar o nome no cartão!",
                          }, {
                            type: "validate",
                            message: "Verifique o nome digitado!",
                          }]}
                        helperText="Digite o nome como aparece no cartão"
                      />


                      <Grid container direction="row" spacing={2}>
                        <Grid item xs={4}>

                          <RHookFormTextField
                            name="cardExpirationMonth"
                            label="Mês"
                            sx={formControlSpacing}
                            fullWidth
                            disabled={!!nominacaoProvisoria || idFormaPagamento !== idCartaoDeCredito}
                            inputProps={{ maxLength: 2 }}
                            rules={{ required: !nominacaoProvisoria?.pago, validate: validarVencimentoDoCartaoPeloPagarme }}
                          />

                        </Grid>
                        <Grid item xs={4}>

                          <RHookFormTextField
                            name="cardExpirationYear"
                            label="Ano"
                            sx={formControlSpacing}
                            fullWidth
                            disabled={!!nominacaoProvisoria || idFormaPagamento !== idCartaoDeCredito}
                            inputProps={{ maxLength: 2 }}
                            rules={{ required: !nominacaoProvisoria?.pago, validate: validarVencimentoDoCartaoPeloPagarme }}
                          />

                        </Grid>
                        <Grid item xs={4}>

                          <RHookFormTextField
                            name="cardCvv"
                            label="CVV"
                            sx={formControlSpacing}
                            fullWidth
                            disabled={!!nominacaoProvisoria || idFormaPagamento !== idCartaoDeCredito}
                            inputProps={{ maxLength: 4 }}
                            rules={{ required: !nominacaoProvisoria?.pago, validate: validarCodigoVerificadorDoCartaoPeloPagarme }}
                          />

                        </Grid>
                      </Grid>
                      <FormHelperText className={(errors?.cardExpirationMonth || errors?.cardExpirationYear || errors?.cardCvv) ? "formError" : null}>{(errors?.cardExpirationMonth?.type === "required" || errors?.cardExpirationYear?.type === "required" || errors?.cardCvv?.type === "required")
                        ? "É necessário informar o vencimento e código verificador do cartão!"
                        : (errors?.cardExpirationMonth?.type === "validate" || errors?.cardExpirationYear?.type === "validate")
                          ? "Verifique o vencimento digitado!"
                          : errors?.cardCvv?.type === "validate"
                            ? "Verifique o código digitado!"
                            : "Informe o vencimento e código verificador do cartão."}
                      </FormHelperText>
                    </React.Fragment>
                  }

                </FormGroup>
              }
            </FormControl>

            <FormControl component="fieldset" variant="standard" sx={formControlStyle}>
              <FormLabel component="legend">Regulamento</FormLabel>
              <FormGroup>

                <Typography>Eu autorizo a nominação do animal, tendo conhecimento do <RouterLink to="/normasnominacao">regulamento</RouterLink> e estou ciente que a nominação só será válida após a confirmação do pagamento.</Typography>

                <RHookFormCheckbox
                  name="autorizo"
                  label="Autorizo"
                  formHelperText=""
                  rules={{ required: true }}
                  onChange={handleChangeAutorizo}
                />


              </FormGroup>
            </FormControl>

          </>
        )


      default:
        return 'Etapa desconhecida';
    }
  }



  // disable next step button if there is an error in one of the form fields in this step
  // or the whole form is not valid on the last step
  const errorListIncludes = (arrayOfNames) => arrayOfNames.reduce((a, b) => a || Object.keys(errors).includes(b), false);
  const forwardButtonDisabled = activeStep => {
    if (activeStep === defaultValuesByStep.length - 1) {
      return !isValid;
    }
    return errorListIncludes(Object.keys(defaultValuesByStep[activeStep]));
  };

  return (

    <>
      <Helmet>
        <title>{`Nominação | ${helmetContext.title}`}</title>
        <meta name="description" content="Nominar seu potro" />
        <meta name="keywords" content="Associação, competições, Rédeas, cavalos, ANCR, Cavalo de rédeas, contato, fale com, telefone, fale com a gente" />
      </Helmet>
      <ScrollToTopOnMount />

      <Grid
        container
        direction="row"
        alignItems="stretch"
        spacing={5}
        sx={{ pt: 2, mb: 0 }}
      >
        <Grid item xs={12} md={9}>

          <Typography variant="h2">Nominação de Potros</Typography>
          <Typography component='p'>Utilize o formulário abaixo para efetuar a nominação.</Typography>

          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>

              <Grid
                container
                direction="row"
                sx={{
                  border: "solid thin #999",
                  p: 2,
                }}
              >

                <Grid item sm={12} >


                  <Stepper activeStep={activeStep} alternativeLabel>

                    <Step key="step01">
                      <StepLabel>Tipo de Nominação</StepLabel>
                    </Step>
                    <Step key="step02">
                      <StepLabel>Informações sobre o potro</StepLabel>
                    </Step>
                    <Step key="step03">
                      <StepLabel>Faturamento</StepLabel>
                    </Step>

                  </Stepper>
                </Grid>

                <Grid item sm={12}>

                  {getStepContent(activeStep)}

                  <div>
                    <Button
                      variant="contained"
                      color="grey"
                      sx={formControlSpacing}
                      disabled={activeStep === 0}
                      onClick={() => { setActiveStep(activeStep => (activeStep > 0) ? activeStep - 1 : activeStep) }}
                    >
                      Anterior
                    </Button>
                    <Button
                      key={activeStep < (defaultValuesByStep.length - 1) ? "button" : "submit"}
                      type={activeStep < (defaultValuesByStep.length - 1) ? "button" : "submit"}
                      color="secondary"
                      variant="contained"
                      disabled={((!isDirty) && (!isValid) && forwardButtonDisabled(activeStep)) || isSubmitting}
                      sx={formControlSpacing}
                      onClick={async () => {
                        const results = await trigger(Object.keys(defaultValuesByStep[activeStep]));
                        if (!results) { };
                        if (activeStep < (defaultValuesByStep.length - 1) && !forwardButtonDisabled(activeStep)) {
                          setActiveStep(activeStep + 1)
                        }
                      }}
                    >
                      {activeStep === 2 ? 'Efetuar nominação' : 'Próximo'}
                    </Button>
                  </div>
                </Grid>


                <Grid item xs={12}>

                  <LinearProgress
                    className={
                      isSubmitting
                        ? ""
                        : "hidden"
                    }
                    sx={{ mt: 1 }}
                  />


                </Grid>
              </Grid>
            </form>
            {DEBUG_FORM_DATA && <RHookFormDataDebugger watch={watch()} errors={errors} isValid={isValid} isDirty={isDirty} control={control} />}
          </FormProvider>

        </Grid>
      </Grid>
      <AlertDialog open={modalOpen} setOpen={setModalOpen} title={modalTitle} message={modalMessage} />
    </>
  )

}