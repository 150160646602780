import React, { useState, useEffect } from 'react';
import { CircularProgress, FormHelperText, Typography, Alert, Grid, Button } from '@mui/material';
import { useGetData } from '../../axiosHook/useRequestData';
import { axiosInstance as axios } from '../../axiosHook/axiosInstance';
import useInterval from '../../useInterval';
import {
  DEBUG_FORM_DATA,
  CHECK_VALID_SESSION_INTERVAL,
} from '../../constants';
import BasicDataTable from '../BasicDataTable';
import ScrollToTopOnMount from '../ScrollToTopOnMount';
import { Helmet } from 'react-helmet-async';
import {
  FormProvider,
  useForm,
} from 'react-hook-form';
import RHookFormSelect from '../../form/RHookFormSelect';
import { RHookFormDataDebugger } from '../../form/RHookFormDataDebugger';
import { formatMoney } from '../../functions';
import {
  // MEMBERSHIP_FEES_PAID_TEST_DATA,
  // CURRENT_MEMBERSHIP_FEES_AVAILABLE_TEST_DATA,
  // NO_CURRENT_MEMBERSHIP_FEES_AVAILABLE_TEST_DATA,
} from '../../test-data';
import PaymentForm from '../entryform/PaymentForm';
import AlertDialog from '../AlertDialog';

const defaultValues = {
  categoriaSocio: '',
};

const MembershipFeeList = (props) => {
  const { helmetContext, session, save, clear } = props;

  const [modalOpen, setModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [modalNavigateUrlOnClose, setModalNavigateUrlOnClose] = useState("");
  const [modalClearSessionOnClose, setModalClearSessionOnClose] = useState(false);

  const checkUserIsAuthenticated = async () => {
    try {
      await axios.get('/checkuserisauthenticated',
        {
          headers: {
            'Authorization': `Bearer ${session.token}`
          },
        });
    } catch (error) {
      // debugging:
      if (process.env.NODE_ENV !== 'production') {
        console.error(error);
      }
      if (error?.response?.status === 401) {
        // if login has expired then show modal and redirect to login page
        setModalMessage({
          type: "error",
          message: "Seu login já expirou. Efetue o login novamente.",
        });
        setModalNavigateUrlOnClose("/login");
        setModalClearSessionOnClose(true);
        setModalOpen(true);
      }
    }
  };

  // check on page mount 
  useEffect(() => {
    if (!session) return;
    checkUserIsAuthenticated();
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // and check again every 5 minutes 
  useInterval(async () => {
    if (!session) return;
    checkUserIsAuthenticated();
  }, CHECK_VALID_SESSION_INTERVAL);

  // if not logged in then show modal and redirect to login page
  if (!session && !modalOpen) {
    setModalMessage("Favor efetuar o login");
    setModalNavigateUrlOnClose("/login");
    setModalOpen(true);
    // return;
  }

  const methods = useForm({
    defaultValues,
    mode: "onSubmit",
    reValidateMode: "onChange",
  });

  const {
    handleSubmit,
    formState: { errors, isValid, isDirty },
    watch,
    control,
  } = methods;

  // const membershipFeesPaid = MEMBERSHIP_FEES_PAID_TEST_DATA;
  const [membershipFeesPaid = [], loading, { error, retry, retriesCount }] = useGetData({
    url: `/afiliados/${session.id}/anuidade`,
    headers: {
      'Authorization': `Bearer ${session.token}`
    },
  });

  // const currentMembershipFeesAvailable = CURRENT_MEMBERSHIP_FEES_AVAILABLE_TEST_DATA;
  // const currentMembershipFeesAvailable = NO_CURRENT_MEMBERSHIP_FEES_AVAILABLE_TEST_DATA;
  const [currentMembershipFeesAvailable = [], loading2, { error2 }] = useGetData(`/anuidades_por_temporadas`);

  if (error && error.response?.status !== 401) return <Alert severity="error">{`${error}`}</Alert>;
  if (error2 && error2.response?.status !== 401) return <Alert severity="error">{`${error2}`}</Alert>;
  if (loading || loading2) return <CircularProgress />;

  const tableHeaders = ['Ano', 'Categoria de Sócio'];
  const tableRows = membershipFeesPaid.map(membershipFee => [membershipFee.ano, membershipFee.categoriaSocio.descricao]);
  const showTable = tableRows.length > 0;
  const title = "Anuidades Recebidas";
  const currentYear = new Date().getFullYear();
  const yearsAlreadyPaid = [...new Set(membershipFeesPaid.map(membershipFee => membershipFee.ano))];
  const currentYearIsPaid = yearsAlreadyPaid.includes(currentYear);

  const selectedCategoryId = watch('categoriaSocio', null);
  const selectedCategory = currentMembershipFeesAvailable["hydra:member"]?.find(fee => fee.categoriaSocio.id === selectedCategoryId);
  const getSelectedCategoryDetails = (selectedCategory) =>
    selectedCategory ? (
      <>
        {`Valor: ${formatMoney(selectedCategory.valor)}`}
        <br />
        {`Pagamento: ${selectedCategory.opcaoPagamento.numeroParcelas !== 1 ? 'Até ' : ''
          }${selectedCategory.opcaoPagamento.descricao}`}
        {selectedCategory.opcaoPagamento.numeroParcelas !== 1 && (
          <>
            <sup>*</sup>
            <br />
            <sup>*</sup>
            {`Parcelamento disponível somente para pagamento via cartão de crédito`}
          </>
        )}
      </>
    ) : null;

  // debugging:
  if (process.env.NODE_ENV !== 'production') {
    console.log({ selectedCategoryId });
    console.log("selectedCategory", selectedCategory);
    console.log("session.anuidades", session.anuidades);
    console.log("retriesCount", retriesCount);
  }

  const handlePagamentoButtonClick = () => {
    // save membership fee data to session (in case the page is reloaded before payment is made)
    const anuidade = { selectedCategory };

    // debugging:
    if (process.env.NODE_ENV !== 'production') {
      console.log("session", { ...session, anuidades: anuidade });
    }
    save({ ...session, anuidades: anuidade });
  };

  const showPaymentForm = !!session?.anuidades;
  const maxParcelas = session.anuidades?.selectedCategory?.opcaoPagamento?.numeroParcelas ?? 1;

  return (
    <>
      <Helmet>
        <title>{`${title} | ${helmetContext.title}`}</title>
        <meta name="description" content={"Lista de " + title} />
        <meta name="keywords" content="" />
      </Helmet>
      <ScrollToTopOnMount />

      {showTable && <BasicDataTable
        title={title}
        paper={true}
        headers={tableHeaders}
        rows={tableRows}
      />}

      {!currentYearIsPaid &&
        (currentMembershipFeesAvailable["hydra:member"].length === 0
          ? <Typography variant="h6" align="center" sx={{ mt: 4 }}>Os valores de anuidade para o ano {currentYear} não estão disponíveis ainda. Por favor, retorne quando liberar para efetuar o pagamento.</Typography>
          : <>
            <Grid item xs={12} md={9}>
              <Typography variant="h2" align="center" sx={{ mt: 4 }}>Pagar anuidade para o ano {currentYear}</Typography>

              {showPaymentForm
                ?
                <>
                  <Typography>
                    {`Nome: ${session.nome}`}<br />
                    {`Categoria de Sócio: ${session.anuidades.selectedCategory.categoriaSocio.descricao}`}<br />
                    {getSelectedCategoryDetails(session.anuidades.selectedCategory)}
                  </Typography>
                  <PaymentForm maxParcelas={maxParcelas} session={session} save={save} setModalOpen={setModalOpen} setModalTitle={setModalTitle} setModalMessage={setModalMessage} setModalClearSessionOnClose={setModalClearSessionOnClose} itemType='anuidades' onPaymentSuccess={retry} />
                </>
                :
                <>
                  <Typography variant="h6" align="center">Selecione a categoria de sócio desejada</Typography>
                  <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(true)}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <RHookFormSelect
                            fullWidth
                            formHelperTextErrorClassName="formSelectErrorMessage"
                            name="categoriaSocio"
                            label="Categoria de Sócio"
                            rules={{ required: true }}
                            options={currentMembershipFeesAvailable["hydra:member"]?.map(fee => {
                              return {
                                value: fee.categoriaSocio.id,
                                label: fee.categoriaSocio.descricao,
                              };
                            })}
                            helperText=" "
                            errorMessages={
                              [{
                                type: "required",
                                message: "É necessário selecionar uma categoria de sócio!",
                              }]}
                          />
                          <FormHelperText className={errors?.categoriaSocio ? "formError" : null}>
                            {errors?.categoriaSocio ? errors.categoriaSocio.message : " "}
                          </FormHelperText>
                          {selectedCategory && (
                            <Typography>
                              {getSelectedCategoryDetails(selectedCategory)}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    </form>
                  </FormProvider>

                  <Typography sx={{ mt: 4 }}>Proceder para o formulário de pagamento</Typography>
                  <Button
                    variant="contained"
                    onClick={() => handlePagamentoButtonClick()}
                    sx={{ mt: 1, mr: 1 }}
                    disabled={!isValid || !isDirty || selectedCategoryId === defaultValues.categoriaSocio}
                  >
                    Pagamento
                  </Button>

                  {DEBUG_FORM_DATA && <RHookFormDataDebugger watch={watch()} errors={errors} isValid={isValid} isDirty={isDirty} control={control} />}
                </>}
            </Grid>
          </>
        )
      }
      <AlertDialog open={modalOpen} setOpen={setModalOpen} title={modalTitle} message={modalMessage} navigateUrlOnClose={modalNavigateUrlOnClose} clearSessionOnClose={modalClearSessionOnClose} session={session} clear={clear} />
    </>
  );
};

export default MembershipFeeList;
